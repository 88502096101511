/**
 * @module Column Content
 * @description
 */
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Pagination, Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { trackEvent } from '@/js/helpers/tracking';
import styles from './index.module.css';
import { v4 as uuidv4 } from 'uuid';
import 'swiper/css';
import 'swiper/css/pagination';
import PropTypes from 'prop-types';

const position = 'COLUMN CONTENT';

/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const {
    className = '',
    assetName,
    headline,
    subhead,
    copy,
    items,
    theme,
    isCentered,
    useTiles,
    customStyles,
    tabletLayout,
    iconColor,
    roundedCorners
  } = props;
  const isOdd = ( n ) => n % 2 !== 0;
  const itemHeadlineClass = `h5 ${ styles.component__itemHeadline }`;

  const options = {
    threshold: 1,
    triggerOnce: true
  };
  const { ref, entry } = useInView( options );

  /**
   * @function initMatchHeight
   * @description Initialize our match height script
   */
  const initMatchHeight = () => {
    import( '@tannerhodges/match-height' ).then( ( result ) => {
      const MatchHeight = result;
      MatchHeight.add( 'div[data-match-height]', 'tile-content' );
    } );
  };

  const [ instanceId, setInstanceId ] = useState( );
  useEffect( () => {
    const id = uuidv4();
    setInstanceId( id );
    initMatchHeight();
  }, [] );

  useEffect( () => {
    if ( entry?.isIntersecting === true ) {
      const data = {
        elementType: 'SECTION',
        name: assetName,
        text: `${ position } - HEADLINE VIEWED`
      };
      trackEvent( { action: 'elementViewed', data, event: {} } );
    }
  }, [ entry, assetName ] );

  return (
    <section className={ `component ${ styles.component } ${ className } ${ theme && `u-theme-${ theme }` }` }>
      <div className="l-container">
        { headline && (
          <div className={ `${ styles.component__header } ${ isCentered && styles.component__headerCentered } ` }>
              <h2 ref={ ref } className={ `h2 ${ styles.component__headline } ${ theme && styles[ `component__headline__${ theme }` ] } ${ isCentered && styles.component__headlineCentered } ${ customStyles && customStyles.component__headlineDark }` }>{ headline }</h2>
              <div className={ `${ styles.component__header_v_align }` }>
                <div>
                  { subhead && subhead !== '__none__' && <h3 className={ `h4 ${ styles.component__subhead }` }>{ subhead }</h3>}
                  {/* eslint-disable-next-line no-underscore-dangle */} {/* setHTML() function changes the location of the string we use (comes from preamp asset) */}
                  { copy && copy?.props?.dangerouslySetInnerHTML?.__html !== '__none__' && <p>{ copy }</p> }
                </div>
              </div>
          </div>
        ) }

        <div className={ `${ styles.component__itemList } ${ tabletLayout === '2x2' ? styles.component__itemListTablet : '' } ${ isOdd( items.length ) && styles.component__itemListOdd }` }>
          { useTiles &&
            <>
              <Swiper
                className={ styles.component__slider }
                modules={ [ Pagination, Navigation, A11y ] }
                autoHeight={ false }
                spaceBetween={ 20 }
                slidesPerView={ 1 }
                pagination={ {
                  el: `.swiper-pagination--${ instanceId }`,
                  type: 'bullets',
                  clickable: true
                } }
                navigation={ {
                  nextEl: `.swiper-button-next--${ instanceId }`,
                  prevEl: `.swiper-button-prev--${ instanceId }`
                } }
                breakpoints={ {
                  576: {
                    initialSlide: 1
                  },
                  768: {
                    initialSlide: 0,
                    slidesPerView: 2.1,
                    spaceBetween: 13
                  },
                  1024: {
                    slidesPerView: 3,
                    centeredSlides: false,
                    spaceBetween: 18
                  }
                } }>
                { items.map( ( item, index ) => (
                  <SwiperSlide key={ index } className={ styles.component__slide } >
                    <div className={ `${ styles.component__tile } ${ customStyles && customStyles.component__tile } ${ theme && styles[ `component__tile__${ theme }` ] } ${ roundedCorners && styles.component__tile__roundedCorners }` } key={ index } data-match-height="tile-content">
                      { item.icon && <span className={ `icon icon-${ item.icon } ${ styles.component__icon } ${ theme !== 'dark' && styles.component__iconAlt } ${ iconColor && styles[ `component__icon-${ iconColor }` ] }` } /> }
                      { /* Condition below is to make sure heading level only increases by one See: https://dequeuniversity.com/rules/axe/4.0/heading-order */ }
                      { subhead ?
                        <h4 className={ itemHeadlineClass }>{ item.headline }</h4> :
                        <h3 className={ itemHeadlineClass }>{ item.headline }</h3>
                      }
                      <p>{item.content}</p>
                    </div>
                  </SwiperSlide>
                ) ) }
              </Swiper>

              <div className={ `${ styles.component__navigationContainer } hide-for-medium` }>
                {/* Previous Arrow */}
                <div className={ `swiper-button-prev--${ instanceId } icon icon-chevron-left1 ${ styles.component__swiperBtnPrev } ${ theme && styles[ `component__swiperBtn__${ theme }` ] }` } />
                {/* Pagination Bullets */}
                <div className={ `swiper-pagination ${ theme && `swiper-pagination__${ theme }` } swiper-pagination--${ instanceId }` } />
                {/* Next Arrow */}
                <div className={ `swiper-button-next--${ instanceId } icon icon-chevron-right1 ${ styles.component__swiperBtnNext } ${ theme && styles[ `component__swiperBtn__${ theme }` ] }` } />
              </div>
            </>
          }

          { items.map( ( item, index ) => (
            <div
              className={ `
                ${ styles.component__item }
                ${ useTiles && styles.component__itemTile }
                ${ useTiles && theme && styles[ `component__tile__${ theme }` ] }
                ${ roundedCorners && styles.component__tile__roundedCorners }
              ` }
              key={ index }>
              { item.icon && <span className={ `icon icon-${ item.icon } ${ styles.component__icon } ${ useTiles && theme !== 'dark' && styles.component__iconAlt } ${ customStyles && customStyles.component__icon } ${ iconColor && styles[ `component__icon-${ iconColor }` ] }` }/>}
              { subhead ?
                <h4 className={ `h5 ${ styles.component__itemHeadline }` }>{ item.headline }</h4> :
                <h3 className={ `h5 ${ styles.component__itemHeadline }` }>{ item.headline }</h3>
              }
              <p>{ item.content }</p>
            </div>
          ) )}
        </div>
      </div>
    </section>
  );
};

Component.defaultProps = {
  assetName: 'SECTION | COLUMN CONTENT | DEFAULT',
  headline: 'Get ready for an upgrade.',
  subhead: null,
  copy: null,
  items: [
    {
      icon: 'wifi',
      headline: 'Headline',
      content: 'Content'
    },
    {
      icon: 'wifi',
      headline: 'Headline',
      content: 'Content'
    },
    {
      icon: 'wifi',
      headline: 'Headline',
      content: 'Content'
    }
  ],
  theme: null,
  isCentered: false,
  useTiles: false,
  customStyles: null,
  tabletLayout: null,
  iconColor: null,
  roundedCorners: false
};

Component.propTypes = {
  assetName: PropTypes.string,
  headline: PropTypes.node,
  subhead: PropTypes.node,
  copy: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape( {
      icon: PropTypes.string,
      headline: PropTypes.node,
      content: PropTypes.node
    } )
  ),
  theme: PropTypes.string,
  isCentered: PropTypes.bool,
  useTiles: PropTypes.bool,
  customStyles: PropTypes.objectOf( PropTypes.string ),
  tabletLayout: PropTypes.string,
  iconColor: PropTypes.string,
  roundedCorners: PropTypes.bool
};

export default Component;
