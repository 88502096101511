/**
 * @module CTA Banner
 * @description
 */
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import DynamicButton from '@/blocks/Buttons/DynamicButton';
import { trackEvent } from '@/js/helpers/tracking';
import styles from './index.module.css';

const position = 'BANNER CTA';

/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const {
    assetName,
    copy,
    theme,
    ctaTheme,
    usePhone,
    link,
    customStyles,
    isGdtCta = false
  } = props;

  const options = {
    threshold: 1,
    triggerOnce: true
  };
  const { ref, entry } = useInView( options );

  useEffect( () => {
    if ( entry?.isIntersecting === true ) {
      const data = {
        elementType: 'BANNER',
        name: assetName,
        text: `${ position } - HEADLINE VIEWED`
      };
      trackEvent( { action: 'elementViewed', data, event: {} } );
    }
  }, [ entry, assetName ] );

  const ctaType = usePhone ? 'phone' : isGdtCta ? 'gdt' : 'cart';

  return (
    <section className={ `component ${ styles.component } ${ theme && `u-theme-${ theme }` }` }>
      <div className="l-container">
        <div ref={ ref } className={ styles.component__body }>
          { copy && <p className={ `${ styles.component__copy } ${ customStyles && customStyles.component__copy }` }>{ copy }</p> }
          <DynamicButton
            className={ `btn ${ ctaTheme && `btn--${ ctaTheme }` }` }
            type={ ctaType }
            position={ position }
            title={ link }
            copy={ ctaType === 'gdt' ? 'Check Availability' : undefined }
            { ...link }
          />
        </div>
      </div>
    </section>
  );
};

Component.defaultProps = {
  assetName: 'BANNER | BANNER CTA | DEFAULT',
  usePhone: false,
  copy: 'Ready to find internet plans available in your neighborhood?',
  theme: null,
  ctaTheme: null
};

Component.propTypes = {
  assetName: PropTypes.string,
  copy: PropTypes.string,
  usePhone: PropTypes.bool,
  theme: PropTypes.string,
  ctaTheme: PropTypes.string
};

export default Component;
